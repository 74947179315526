import { createRouter, createWebHistory } from 'vue-router';
const routes = [
    {
        path: '/informasi-harga/',
        component: () => import('@/layout/PriceInfoCustomerLayout.vue'),
        redirect: '/informasi-harga/customer/',
        children: [
            {
                path: 'customer',
                name: 'PriceInfoCustomer',
                component: () => import('@/views/PriceInfoCustomerView.vue'),
            },
            {
                path: 'customer/buat',
                name: 'PriceInfoCustomerCreate',
                component: () => import('@/views/PriceInfoCustomerCreateView.vue'),
            },
            {
                path: 'partner',
                name: 'PriceInfoPartner',
                component: () => import('@/views/PriceInfoPartnerView.vue'),
            },
            {
                path: 'partner/buat',
                name: 'PriceInfoPartnerCreate',
                component: () => import('@/views/PriceInfoPartnerCreateView.vue'),
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory((import.meta.env || process.env).BASE_URL),
    routes,
});
export default router;
